/*-- -------------------------- -->
<--- NAVIGATION changes color       -->
<--- -------------------------- -*/
@media only screen and (min-width: 1024px) {
  .home-page {
    /* Change color to white on the home page */
  }
  .home-page #navigation #navbar-menu ul li a {
    color: white;
  }
  .home-page #navigation #navbar-menu ul li a:before {
    background: var(--primary);
  }
  .home-page #navigation #navbar-menu ul li a.active:before {
    color: var(--primary);
  }
  .home-page .logo-svg {
    filter: invert(100%) sepia(0%) saturate(0%) brightness(2) contrast(1);
  }
}
/*-- -------------------------- -->
  <---           Hero 1           -->
  <--- -------------------------- -*/
/* Mobile - 360px */
@media only screen and (min-width: 0px) {
  #hero {
    font-family: 'Manrope', 'Arial', sans-serif;
    text-align: center;
    position: relative;
    z-index: 2;
    overflow: hidden;
    padding: var(--sectionPadding);
    padding-top: clamp(9.25rem, 21.82vw, 14rem);
    background: #553DE9 linear-gradient(to top right, #200ba7 20rem, #688ddc 100%);
    /* IMAGE MAN */
    /*  TEXT CONTAINER  */
    /* BACKGROUND PICTURE */
  }
  #hero:before {
    content: "";
    height: 100%;
    width: 100%;
    opacity: 0.25;
    background-size: auto;
    background: url("/assets/images/pattern.webp") repeat;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
  #hero .corner-top,
  #hero .corner-bottom {
    display: none;
  }
  #hero .cs-container {
    width: 100%;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    max-width: 54.192rem;
    position: relative;
    z-index: 10;
    gap: 1.6rem;
  }
  #hero .cs-container .cs-picture {
    width: 81%;
  }
  #hero .cs-container .cs-picture img {
    width: 100%;
    height: auto;
  }
  #hero .cs-left {
    margin: auto;
    width: 100%;
    max-width: 53.7rem;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    box-sizing: border-box;
  }
  #hero .cs-left .cs-topper {
    color: white;
    text-align: center;
    width: 100%;
    padding-bottom: clamp(1rem, 2vw, 2.4rem);
    letter-spacing: 0.15rem;
  }
  #hero .cs-left .container-buton {
    gap: 1.1rem;
    display: flex;
    margin-bottom: clamp(2rem, 6.6vw, 4rem);
    align-items: center;
    justify-content: center;
    width: 100%;
    /* BUTTON */
  }
  #hero .cs-left .container-buton .cs-button-solid {
    font-size: 1.6rem;
    line-height: clamp(2.87em, 5.5vw, 3.5em);
    width: 20.8rem;
    text-decoration: none;
    font-weight: 600;
    color: var(--headerColor);
    padding: 0;
    background-color: var(--primary);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 1.2rem;
    border-radius: 50px;
    position: relative;
    z-index: 1;
    transition: background-color 0.3s, font-weight 0.3s, transform 0.3s;
  }
  #hero .cs-left .container-buton .cs-button-solid:hover {
    background-color: var(--primaryDark);
    font-weight: bold;
    transform: scale(1.01);
  }
  #hero .cs-left .container-buton .cs-button-solid:hover:before {
    width: 100%;
    border-radius: 5px;
  }
  #hero .cs-left .container-buton .button-secondary {
    width: 50%;
  }
  #hero .cs-title {
    font-size: clamp(2.8rem, 6vw, 4.6rem);
    font-weight: bold;
    line-height: 1.2em;
    text-align: center;
    width: 100%;
    color: var(--bodyTextColorWhite);
    position: relative;
    padding-bottom: clamp(2.4rem, 5.5vw, 2.2rem);
  }
  #hero .cs-text {
    font-size: clamp(1.4rem, 1.95vw, 1.6rem);
    line-height: 1.5em;
    width: 100%;
    margin: 0 auto clamp(2rem, 6.6vw, 4.8rem) 0;
    color: var(--bodyTextColorWhite);
    max-width: 56.4rem;
    text-align: center;
    hyphens: auto;
    word-wrap: break-word;
    overflow-wrap: break-word;
  }
  #hero .services-list {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    gap: clamp(1.2rem, 2vw, 3.5rem);
  }
  #hero .services-list li {
    font-size: clamp(1.2rem, 2vw, 1.6rem);
    font-weight: 700;
    color: #fff;
    display: inline-flex;
    align-items: center;
    gap: clamp(0.8rem, 2vw, 1.5rem);
  }
  #hero .services-list li i {
    background: rgba(255, 255, 255, 0.1);
    padding: 1rem;
    border-radius: 1.3rem;
    font-size: clamp(1.4rem, 3.7vw, 2rem);
    width: clamp(2.8rem, 7.4vw, 4.8rem);
    height: clamp(2.8rem, 7.4vw, 4.8rem);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  #hero .review {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    color: var(--headerColor);
    gap: 0.7rem;
    border-radius: 200px;
    padding: 4.2rem 0rem 1rem 0;
    margin: auto;
    max-width: 55rem;
  }
  #hero .review .google-review {
    display: flex;
    flex-direction: column;
    gap: 0;
  }
  #hero .review .google-review .p3 {
    padding-bottom: 0 !important;
    color: white;
    font-weight: normal;
    font-size: clamp(1.3rem, 1.5vw, 1.6rem);
  }
  #hero .review .stars-rating {
    display: flex;
    gap: 0.5rem;
  }
  #hero .stars-rating i {
    color: var(--primary);
    font-size: clamp(1.5rem, 2vw, 2rem);
  }
}
/* Tablet - 768px */
@media only screen and (min-width: 768px) {
  #hero .cs-container .cs-picture {
    width: 100%;
  }
  #hero .corner-bottom {
    display: inline;
    position: absolute;
    width: clamp(100px, 10vw, 200px);
    z-index: 0;
  }
  #hero .corner-top {
    display: inline;
    position: absolute;
    width: clamp(100px, 10vw, 200px);
    z-index: 0;
    top: -5px;
    left: -5px;
  }
  #hero .corner-bottom {
    bottom: -5px;
    right: 83rem;
    position: absolute;
    display: inline;
    z-index: 0;
  }
}
/* Desktop - 1024px */
@media only screen and (min-width: 1200px) {
  #hero .cs-container {
    flex-direction: row-reverse;
    padding-top: 5rem;
    max-width: 130rem;
    gap: 3rem;
  }
  #hero .cs-right {
    margin-top: -11rem;
  }
  #hero .cs-left .cs-topper {
    text-align: left;
    font-size: 1.4rem;
  }
  #hero .cs-left .cs-title {
    text-align: left;
  }
  #hero .cs-left .cs-text {
    text-align: left;
  }
  #hero .cs-left .container-buton {
    align-items: flex-start;
    justify-content: flex-start;
  }
  #hero .review {
    max-width: 130rem;
  }
}
/*-- -------------------------- -->
  <---       Side By Side         -->
  <--- -------------------------- -*/
/* Mobile - 360px */
@media only screen and (min-width: 0em) {
  #sbs {
    padding: var(--sectionPadding);
    background-color: #D3DFF8;
  }
  #sbs .cs-container {
    width: 100%;
    max-width: 54.192rem;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    row-gap: clamp(3rem, 3vw, 7.4rem);
  }
  #sbs .cs-right {
    width: 100%;
    max-width: 40rem;
    min-height: clamp(25rem, 68vw, 37.5rem);
    position: relative;
    align-self: center;
  }
  #sbs .cs-right .cs-picture img {
    width: 100%;
    height: auto;
    display: block;
  }
  #sbs .cs-left {
    max-width: 64.8rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  #sbs .cs-title {
    width: 100%;
    text-align: left;
    color: var(--headerColor);
    position: relative;
  }
  #sbs .services-list {
    list-style: none;
    font-weight: 600;
    line-height: 30;
    margin-bottom: 3rem;
  }
  #sbs .services-list li {
    font-size: clamp(1.6rem, 1.2vw, 1.8rem);
    margin-bottom: 1.6rem;
    display: flex;
    align-items: center;
  }
  #sbs .services-list li picture {
    margin-right: 1.5rem;
  }
}
/* Desktop - 1024px */
@media only screen and (min-width: 1024px) {
  #sbs .cs-container {
    flex-flow: row;
    justify-content: space-between;
    gap: 3.25rem;
    max-width: 130rem;
  }
  #sbs .cs-right {
    max-width: 47.8rem;
  }
}
/*# sourceMappingURL=critical.css.map */